import { render, staticRenderFns } from "./ReorderVehiclesForm.vue?vue&type=template&id=1653e3ae&"
import script from "./ReorderVehiclesForm.vue?vue&type=script&lang=js&"
export * from "./ReorderVehiclesForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports