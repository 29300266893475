<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div
      style="
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 1fr;
        gap: 8px;
      "
    >
      <div
        v-for="(list, group) in listGroup"
        :key="group"
        :class="`form-wrapper full`"
      >
        <div>
          <strong>{{ group }}</strong>
        </div>

        <hr />

        <draggable
          :value="list"
          group="vehicles"
          @change="(e) => handleReorder(e, group)"
        >
          <div
            v-for="(vehicle, index) in list"
            :key="vehicle.id"
            style="
              border-radius: 12px;
              border: 1px solid rgba(209, 213, 219, 1);
              padding: 8px 12px;
            "
            :style="{ 'margin-top': index === 0 ? '0px' : '8px' }"
          >
            <div style="display: flex; gap: 12px">
              <img
                :src="vehicle.exterior_photos[0]"
                style="width: 80px; height: 52px; border-radius: 8px"
              />

              <div>
                <div
                  style="
                    font-weight: 600;
                    font-size: 12px;
                    color: rgba(156, 163, 175, 1);
                  "
                >
                  {{ vehicle.express_auction_entry_column }}
                  {{ vehicle.express_auction_entry_number }}
                </div>

                <div
                  style="
                    font-weight: 700;
                    font-size: 16px;
                    color: rgba(31, 42, 55, 1);
                  "
                >
                  {{ vehicle.make.toUpperCase() }}
                  {{ vehicle.id.toUpperCase() }}
                </div>

                <div
                  style="
                    font-weight: 700;
                    font-size: 12px;
                    color: rgba(55, 65, 81, 1);
                  "
                >
                  {{ vehicle.model.toUpperCase() }}
                  {{ vehicle.series.toUpperCase() }}
                  {{ vehicle.model_year }}
                </div>
              </div>
            </div>
            <hr style="margin-block: 12px" />
            <div
              style="display: flex; justify-content: space-between; gap: 12px"
            >
              <div
                style="
                  font-size: 12px;
                  font-weight: 500;
                  color: rgba(107, 114, 128, 1);
                "
              >
                {{ vehicle.odometer_mileage }}
                {{ vehicle.odometer_type.toUpperCase() }}
              </div>

              <div
                style="
                  font-size: 12px;
                  font-weight: 500;
                  color: rgba(107, 114, 128, 1);
                "
              >
                <span style="margin-right: 8px">•</span>
                {{ vehicle.pickup_location }}
              </div>

              <div
                style="
                  font-size: 12px;
                  font-weight: 500;
                  color: rgba(107, 114, 128, 1);
                "
              >
                <span style="margin-right: 8px">•</span>
                {{ vehicle.organization.name }}
              </div>
            </div>
            <!-- <template #header>
              <div style="font-size: 12px">
                <strong>
                  {{ vehicle.express_auction_entry_column }}
                  {{ vehicle.express_auction_entry_number }}
                </strong>
                <span>: {{ $objectDenomination(vehicle) }}</span>
              </div>
            </template>

            <img
              :src="vehicle.exterior_photos[0]"
              style="width: 100%; height: 100px"
            /> -->
          </div>
        </draggable>
      </div>
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{ $t("COMMON.SAVE") }}
      </base-button>
    </div>
  </form>
</template>

<script>
import { Card } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { EXPRESS_ENTRY_COLUMNS } from "@/constants/auctions";
import draggable from "vuedraggable";

export default {
  layout: "DashboardLayout",

  components: {
    flatPicker,
    BaseInput,
    ValidationError,
    HtmlEditor,
    [Card.name]: Card,
    draggable,
  },

  mixins: [formMixin],

  props: ["vehicles", "canEdit", "formErrors", "loading"],

  data() {
    return {
      EXPRESS_ENTRY_COLUMNS,
      listGroup: {},
    };
  },

  computed: {
    total() {
      return Object.keys(this.listGroup).length;
    },
  },

  created() {},

  methods: {
    handleReorder(event, group) {
      console.log("handleReorder", event, group);

      const groups = { ...this.listGroup };

      if (event.added) {
        groups[group].push({
          ...event.added.element,
          express_auction_entry_column: group,
        });
      }

      if (event.moved) {
        const currentItem = groups[group][event.moved.newIndex];

        const movedItem = groups[group][event.moved.oldIndex];

        groups[group][event.moved.newIndex] = {
          ...movedItem,
          express_auction_entry_column: group,
        };

        groups[group][event.moved.oldIndex] = {
          ...currentItem,
          express_auction_entry_column: group,
        };
      }

      if (event.removed) {
        groups[group].splice(event.removed.oldIndex, 1);
      }

      groups[group] = groups[group].map((item, index) => {
        return {
          ...item,
          express_auction_entry_number: index + 1,
        };
      });

      this.listGroup = { ...groups };
    },
    async handleSubmit() {
      this.$emit("formSubmitted", this.listGroup);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    vehicles(vehicles) {
      this.listGroup = vehicles;
    },
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },
};
</script>
