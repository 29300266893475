<template>
  <div class="container-fluid">
    <ReorderVehiclesForm
      :loading="loading"
      :canEdit="canEdit"
      :vehicles="vehicles"
      :formErrors="formErrors"
      @formSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import alertLeave from "@/mixins/alert-leave-mixin";
import ReorderVehiclesForm from "../partials/ReorderVehiclesForm.vue";
import { EXPRESS_ENTRY_COLUMNS } from "@/constants/auctions";

export default {
  layout: "DashboardLayout",

  components: { ReorderVehiclesForm },

  mixins: [alertLeave],

  props: {
    eventId: {
      type: [String, Number],
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      vehicles: [],
      loading: false,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;

      try {
        const params = {
          filter: {
            event_id: this.eventId,
          },
          include: "organization",
          sort: "express_auction_entry_column,express_auction_entry_number",
        };

        await this.$store.dispatch("vehicles/list", params);
        const list = this.$store.getters["vehicles/list"];

        this.vehicles = Object.groupBy(
          list.sort((a, b) =>
            a.express_auction_entry_column.localeCompare(
              b.express_auction_entry_column
            )
          ),
          (v) => v.express_auction_entry_column
        );

        EXPRESS_ENTRY_COLUMNS.forEach((column) => {
          if (!this.vehicles[column]) {
            this.vehicles[column] = [];
          }
        });

        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        this.loading = false;
        throw error;
      }
    },
    async handleSubmit(reorderedVehicles) {
      this.loading = true;

      try {
        const map = Object.entries(reorderedVehicles).reduce(
          (acc, currentList) => {
            console.log("currentList", currentList);
            return [
              ...acc,
              ...currentList[1].map((vehicle) => {
                return {
                  id: vehicle.id,
                  express_auction_entry_column:
                    vehicle.express_auction_entry_column,
                  express_auction_entry_number:
                    vehicle.express_auction_entry_number,
                };
              }),
            ];
          },
          []
        );

        await this.$store.dispatch("events/reorganizeVehicles", {
          eventId: this.eventId,
          vehicles: map,
        });

        this.$notify({
          type: "success",
          message: this.$t("EVENTS.REORGANIZED_VEHICLES"),
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        throw error;
      }

      this.loading = false;
    },
  },
};
</script>
